import axios from 'axios';
import router from '@/router/index'
import { Dialog } from 'vant'
import{ AES } from "../plugins/md5"
// 创建axios实例
const http = axios.create({
    // baseURL: window.atob("aHR0cHM6Ly9wbXBvd2VyLXRlc3Quc2llbWVucy1oZWFsdGhpbmVlcnMuY24vYXBpLw"),//pmpower测试环境接口btoa加密
    baseURL: window.atob("aHR0cHM6Ly9wbXBvd2VyLnNpZW1lbnMtaGVhbHRoaW5lZXJzLmNuL2FwaS8="),//pmpower生产环境接口btoa加密
    timeout: 8000
});
// 请求拦截器
http.interceptors.request.use(function (config) {
    if (sessionStorage.getItem('SESSIONTOKEN')) {
        let token = AES.decrypt(sessionStorage.getItem('SESSIONTOKEN'))
        config.headers.Authorization = token;
        // config.headers.Authorization = sessionStorage.getItem('SESSIONTOKEN');
      }
   
    if (config.method.toLowerCase() == 'get') {
        config.params = config.data
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 响应拦截器
http.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response){
        if(error.response.status===401){
            Dialog.alert({
                message: "登录失效,请重新登录"
              }).then(() => {
                sessionStorage.removeItem('SESSIONTOKEN')
                router.push({
                    name:"Home"
                })
              })
           
        }
    }
    return Promise.reject(error);
});
export default http;

