// 引入axios实例
import request from "./http";
import axios from "./pmHttp";

  async function Auth(data) {
    return await request({
      method: "post",
      url: "User/Auth",
      data,
    });
  }
  async function GetDevicesByName(data) {
    return await request({
      method: "get",
      url: "cad/Library/GetDevicesByName",
      data,
    });
  }
  async function GetDeviceTypeByNameWithApp(data) {
    return await request({
      method: "get",
      url: "cad/Library/GetDeviceTypeByNameWithApp",
      data,
    });
  }
  
  async function GetModelLimitByName(data) {
    return await request({
      method: "get",
      url: "cad/Config/GetModelLimitByName",
      data,
    });
  }
  async function GetModelTipLimitByName(data) {
    return await request({
      method: "get",
      url: "cad/Config/GetModelTipLimitByName",
      data,
    });
  }


  // 西门子接口
  async function GetProjectInfo(data) {
    return await axios({
      method: "get",
      url: "External/GetProjectInfo",
      data,
    });
  }
export {Auth, GetDevicesByName,GetDeviceTypeByNameWithApp,GetProjectInfo,GetModelLimitByName,GetModelTipLimitByName};
