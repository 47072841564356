<template>
  <div>
    <van-popup
      duration="0"
      v-model="show"
      closeable
      @open="openPopup"
      @close="closePopup"
      @click-close-icon="closeIcon"
    >
      <slot></slot>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    closeIcon() {
      this.$emit("closeIcon");
    },
    openPopup() {
      this.show = true;
    },
    closePopup() {
      this.show = false;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .van-popup--center {
  width: 580px;
  height: 570px;
  border-radius: 10px;
  position: fixed;
}
::v-deep .van-icon-cross:before {
  font-size: 40px;
  color: #000000;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>