<template>
  <div class="zhu-list">
    <div class="zhu-item">
      <span class="zhu-num"
        >柱子
        <slot></slot>
      </span>
      <span @click="setParams" class="zhu-btn"><van-icon name="arrow" /> </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
      setParams(){
          this.$emit("setZhuParams","true")
      }
  },
};
</script>
<style scoped lang="scss">
.zhu-list {
  background-color: #fff;
  margin-bottom: 10px;
  padding-left: 12px;
  margin-top: -10px;
  // line-height: 41px;
  .zhu-item {
    border-bottom: 1px solid #eee;
    padding: 27px 20px;
    font-weight: bold;
    color: #404040;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    &:nth-last-child(1) {
      border-bottom: 0;
    }
  }
  .zhu-num {
    font-weight: bold;
    color: #404040;
    font-size: 24px;
  }
  .zhu-btn {
    font-size: 24px;
    font-weight: bold;
    padding: 0 18px;
  }
}
</style>